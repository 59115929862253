<template>
    <v-dialog :value="true" overlay-opacity="0.6" overlay-color="#000" @input="close()" max-width="500px">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center">Photo Attachments</h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-divider />
                <v-container pa-0 pt-4 pb-3 px-5>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <p class="subtitle-1 mb-0">Photos help you provide more detail to your emergency notes.</p>
                            <p class="body-2 mt-1 mb-2">Only image files (e.g. 'jpg', 'png', etc.) are allowed for uploads. While we're sure you trust your emergency contacts, please note that (as with any app) all media can be saved, duplicated and distributed. As such, please use caution when sharing important details in your photos.</p>
                            <p class="body-2 mb-2">Document uploads are not available as additional security implications may arise. Instead, it may be helpful to provide a link to any important documents residing in your preferred cloud vendor (e.g. Google Drive) in your note's details.</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="card-actions secondary">
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="px-10" @click.native="close()">Got It</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {

    name: 'PhotoInfoModal',
    props: {
    },
    computed: {
    },
    data: () => ({
    }),
    methods: {
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
    }
}
</script>
